<template>
  <div class="mt-12">
    <v-row v-if="isReportItemVisible('showVideoRecord')">
      <v-col>
        <p class="primary--text mb-3">
          <b>Видеозапись центра оценки или развития:</b>
        </p>
        <v-card elevation="0" height="400px" color="black" rounded="0">
          <iframe
            v-if="videoRecordLink"
            style="width: 100%; height: 100%"
            :src="videoRecordLink"
            frameborder="0"
            allow="autoplay"
          ></iframe>
          <div
            v-else
            class="d-flex align-center justify-center text-center h-100"
          >
            <p class="hint">Видео не прикреплено</p>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="videoCommentByExpert.trim()" class="pa-3 pt-8">
        <div
          class="pa-3"
          style="min-height: 400px"
          v-html="replacer(videoCommentByExpert)"
        />
      </v-col>
      <v-col v-else />
    </v-row>

    <v-row class="mt-8" v-if="isReportItemVisible('showEmailTaskAnswers')">
      <v-col>
        <p class="primary--text mb-3">
          <b>Письма в симуляции:</b>
        </p>
        <v-card style="min-height: 400px; overflow-y: auto">
          <expansion-list
            v-if="!!emails.length"
            :items="emails"
            :multiple="false"
            firstSelected
            @clickOnHeader="selectEmail($event)"
          >
            <template v-slot:header="{ item }">
              <div>{{ item.title }}</div>
            </template>
            <template v-slot:content="{ item }">
              <Email
                :message="item"
                :session="{}"
                type="incoming"
                :isViewMode="true"
              />
            </template>
          </expansion-list>
          <p v-else>Писем нет</p>
        </v-card>
      </v-col>
      <v-col>
        <p class="primary--text mb-3">
          <b>Ответ участника</b>
        </p>
        <v-card class="pa-0" style="min-height: 400px">
          <Email
            v-if="!!selectedEmailAnswer"
            :message="selectedEmailAnswer"
            :session="{}"
            type="outgoing"
            :isViewMode="true"
          />
          <p v-else class="pa-3" style="color: #767676">
            Участник не ответил на письмо
          </p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";

import { useLineBreakReplacer } from "@/use/helpers";

import Email from "@/components/shared/assets/Email";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const { replacer } = useLineBreakReplacer();

    const isReportItemVisible = store.getters.isReportItemVisible;

    const videoRecordLink = computed(
      () => store.getters.getCurrentReport.videoRecordLink
    );
    const videoCommentByExpert = computed(
      () => store.getters.getCurrentReport.videoCommentByExpert
    );

    const emails = computed(
      () => store.getters.getReportAnswers.incomingEmails
    );

    const selectedEmail = ref(emails.value[0]);
    const selectedEmailAnswer = computed(
      () => store.getters.getReportAnswers.sentEmails[selectedEmail.value.id]
    );
    const selectEmail = index => (selectedEmail.value = emails.value[index]);

    return {
      replacer,
      isReportItemVisible,
      videoRecordLink,
      videoCommentByExpert,
      emails,
      selectedEmail,
      selectedEmailAnswer,
      selectEmail,
    };
  },
  components: {
    Email,
  },
};
</script>
