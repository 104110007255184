<template>
  <v-card elevation="0">
    <v-card-text>
      <div v-if="!isReplyOpen && type != 'outgoing'">
        <p>Тема письма:</p>
        <div class="d-flex justify-space-between mt-1 mb-4 pb-4 brd-b">
          <v-list-item-title
            style="font-weight: 500; font-size: 16px; white-space: normal"
            class="text--primary"
            v-text="message.title"
            :title="message.title"
          ></v-list-item-title>
          <v-list-item-subtitle style="max-width: 110px" class="text-right">
            {{ digitDate(message.receiptTime) }}
            {{ digitTime(message.receiptTime) }}
          </v-list-item-subtitle>
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div class="d-flex mb-1">
          <v-list-item-subtitle style="max-width: 62px; min-width: 62px">
            {{ type === "outgoing" ? "Кому" : "От кого:" }}
          </v-list-item-subtitle>
          <p class="text--primary" style="font-weight: 500">
            {{ message.from }}
          </p>
        </div>
        <v-list-item-subtitle
          v-if="isReplyOpen || type == 'outgoing'"
          style="max-width: 110px"
          class="text-right"
        >
          {{ digitDate(message.receiptTime) }}
          {{ digitTime(message.receiptTime) }}
        </v-list-item-subtitle>
      </div>
      <div v-if="message.copy.length" class="d-flex mb-1">
        <v-list-item-subtitle style="max-width: 62px; min-width: 62px"
          >Копия:
        </v-list-item-subtitle>
        <p class="text--primary" style="font-weight: 500">
          {{ message.copy.filter(Boolean).join("; ") }}
        </p>
      </div>

      <div v-if="isReplyOpen || type == 'outgoing'" class="d-flex mb-1">
        <v-list-item-subtitle style="max-width: 57px; min-width: 57px"
          >Тема:
        </v-list-item-subtitle>
        <p class="text--primary" style="font-weight: 500">
          {{ message.title }}
        </p>
      </div>
      <div
        class="py-3"
        style="
          font-size: 14px;
          border: 1px solid rgba(169, 169, 170, 0.2);
          border-radius: 6px;
        "
      >
        <p
          class="px-3 mt-1 text--primary"
          style="min-height: 200px"
          v-html="replacer(message.text || '')"
        ></p>
      </div>
      <div
        v-if="message.documents && message.documents.length"
        class="mt-5 mx-1 brd-a"
        style="border-radius: 6px"
      >
        <v-list>
          <v-list-item
            v-for="(doc, index) in message.documents"
            :key="index"
            :title="doc.name"
            class="my-1"
            style="min-height: 24px"
          >
            <a :href="doc.link" target="_blank">
              <div class="d-flex align-center">
                <div class="mr-1 mb-1" style="width: 15px; height: 15px">
                  <img :src="require('@/assets/img/document2.svg')" />
                </div>

                <v-list-item-content class="py-1">
                  <p style="font-size: 14px">{{ doc.name }}</p>
                </v-list-item-content>
              </div>
            </a>
          </v-list-item>
        </v-list>
      </div>
      <div
        v-if="
          !isReplyOpen &&
          !isViewMode &&
          type != 'outgoing' &&
          (!session.sentEmails[message.id] ||
            (session.sentEmails[message.id] &&
              !session.sentEmails[message.id].id))
        "
        class="d-flex justify-end mt-5 mx-1"
      >
        <v-btn class="form-btn" @click="$emit('reply')"> Ответить </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { useLineBreakReplacer, useFormattedDates } from "@/use/helpers";
import { watch } from "@vue/composition-api";

export default {
  props: ["message", "session", "isReplyOpen", "type", "isViewMode"],
  setup(_, { emit }) {
    const { replacer } = useLineBreakReplacer();

    const { digitDate, digitTime } = useFormattedDates();

    if (_.message.draft?.isOpen) {
      emit("reply");
    }

    watch(
      () => _.message,
      val => {
        if (val.draft?.isOpen) {
          emit("reply");
        }
      }
    );

    return {
      replacer,
      digitDate,
      digitTime,
    };
  },
};
</script>
